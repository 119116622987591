import React from "react";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import Link from "../Link.js";
import { Card, Title } from "@yoast/ui-library";
import styles from "./SupportCardStyles.scss";

const messages = defineMessages( {
	supportHeader: {
		id: "support.card.header",
		defaultMessage: "Support",
	},
	supportIntro: {
		id: "support.card.intro",
		defaultMessage: "If you have a question, if you need help, or if you just want to contact us, " +
			"there are several ways to get in touch.",
	},
	supportKbLink: {
		id: "support-card-kb-link",
		defaultMessage: "Knowledge base",
	},
	supportKb: {
		id: "support.kb",
		defaultMessage: "The Yoast Knowledge Base is always a good place to start if you have a problem or a question. " +
			"You can check the list of articles, or easily search the knowledge base to find the answers you're looking for.",
	},
	supportForumsLink: {
		id: "support-card-support-forums-link",
		defaultMessage: "Free support forums",
	},
	supportForums: {
		id: "support.forums",
		defaultMessage: "On the support forums at wordpress.org you can post about your issues, " +
			"and others will help you out.",
	},
	supportForumsDetail: {
		id: "support.forumsDetail",
		defaultMessage: "To let others help you, please include as much detail in your description as possible.",
	},
} );

/**
 * A function that returns the SupportCard component.
 *
 * @param {Object} props The props required for the SupportCard component.
 *
 * @returns {ReactElement} The SupportCard component.
 */
const SupportCard = () => {
	return (
		<Card>
			<div className={ styles.supportCardHeader }>
				<Title as="h2" size="4">
					<FormattedMessage { ...messages.supportHeader } />
				</Title>
				<p>
					<FormattedMessage { ...messages.supportIntro } />
				</p>
			</div>
			<Card.Content>
				<ul className={ styles.supportList }>
					<li className={ styles.supportItem }>
						<Link to={ "https://yoast.com/help/" } linkTarget="_blank" hasExternalLinkIcon={ true }>
							<FormattedMessage { ...messages.supportKbLink } />
						</Link>
						<p>
							<FormattedMessage { ...messages.supportKb } />
						</p>
					</li>
					<li className={ styles.supportItem }>
						<Link to={ "https://yoast.com/help/support/" } linkTarget="_blank" hasExternalLinkIcon={ true }>
							<FormattedMessage { ...messages.supportForumsLink } />
						</Link>
						<p>
							<FormattedMessage { ...messages.supportForums } />
						</p>
						<p>
							<FormattedMessage { ...messages.supportForumsDetail } />
						</p>
					</li>
				</ul>
			</Card.Content>
		</Card>
	);
};

export default injectIntl( SupportCard );
