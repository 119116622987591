import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import util from "util";
import noop from "lodash/noop";
import { debounce } from "lodash/function";
import Toggle from "../../Toggle";
import classNames from "classnames";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Table, Link, Button, Title, Badge } from "@yoast/ui-library";

import styles from "./styles.scss";
import getEnv from "../../../../../shared-frontend/functions/getEnv";

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

const messages = defineMessages( {
	toggleAriaLabel: {
		id: "site.subscriptionDetail.toggle",
		defaultMessage: "Enable subscription for: %s",
	},
	toggleAriaLabelProvisioned: {
		id: "site.subscriptionDetail.toggleProvisioned",
		defaultMessage: "Disabled, because the subscription is bought via a third party",
	},
	provisionedByMessage: {
		id: "site.subscriptionDetail.provisionedBy",
		defaultMessage: "Bought via {name}",
	},
} );

// eslint-disable-next-line require-jsdoc
function getManageSectionForThirdParty( props ) {
	const provisionerName = props.provisionerName || "third party";

	return <Table.Cell
			className={ classNames( styles.column, styles.fixed, styles.fourthColumn, { [ styles.isEnabled ]: props.isEnabled } ) }
	>
		<div className={ styles.provisionerSection }>
			<FormattedMessage { ...messages.provisionedByMessage } values={ { name: provisionerName } }/>
		</div>
	</Table.Cell>;
}

// eslint-disable-next-line require-jsdoc
function getManageSectionForYoast( props, subscriptionId ) {
	return <Table.Cell
			className={ classNames( styles.column, styles.fixed, styles.fourthColumn, { [ styles.isEnabled ]: props.isEnabled } ) }
	>
		<Button
				as={ NavLink } to={ `/account/subscriptions/${ subscriptionId }` }
				type="button"
				className={ styles.manage }
		>
			<FormattedMessage id="subscriptions.buttons.manage" defaultMessage="Manage"/>
		</Button>
	</Table.Cell>;
}

// eslint-disable-next-line require-jsdoc
function getManageSectionForBuyButton( props ) {
	return <Table.Cell
			className={ classNames( styles.column, styles.fixed, styles.fourthColumn, { [ styles.isEnabled ]: props.isEnabled } ) }
	>
		<Button
				as="a"
				href={ getEnv( "SHOP_URL", "https://yoast.test" ) + "/shop/" }
				variant="upsell"
				target="_blank"
		>
			<FormattedMessage id="subscriptions.buttons.buy" defaultMessage="Buy"/>
		</Button>
	</Table.Cell>;
}


// eslint-disable-next-line require-jsdoc
function getManageSection( props ) {
	const isEnabled = props.isEnabled;
	const isPurchasedViaThirdParty = !! props.accessGivenByProvisionedSubscription;

	if ( isEnabled && isPurchasedViaThirdParty ) {
		return getManageSectionForThirdParty( props );
	}

	const hasManageableSubscription = !! props.manageButtonSubscriptionId;
	if ( hasManageableSubscription ) {
		return getManageSectionForYoast( props, props.manageButtonSubscriptionId );
	}

	return getManageSectionForBuyButton( props );
}

/**
 * Default activatable product row.
 *
 * @param {object} props The props.
 *
 * @returns {ReactElement} The siteSubscriptionDetail element.
 */
function siteSubscriptionDetailChangeable( props ) {
	const rowProps = [];
	if ( props.background ) {
		rowProps.background = props.background;
	}

	const anotherLicense = (
			<Button
					as="a"
					href={ getEnv( "SHOP_URL", "https://yoast.test" ) + "/shop/" }
					variant="secondary"
					size="small"
					className={ styles.subscriptionButton }
					target="_blank"
			>
				<FormattedMessage
						id="site.subscriptions.licenses.add"
						defaultMessage="Get another subscription"
				/>
				<PlusIcon className="plusIcon"/>
			</Button>
	);

	let locked = props.limit >= props.used;
	if ( props.subscriptionId !== "" ) {
		locked = false;
	}

	/**
	 * Send a request to toggle the subscription if the status is different from the current one.
	 *
	 * @param {bool} toggleStatus The status of the toggle.
	 *
	 * @returns {void}
	 */
	function onTogglePress() {
		if ( locked ) {
			return props.onToggleDisabled( props.sourceId );
		}

		props.onToggleSubscription( props.subscriptionId, props.sourceId, props.name, ! props.isEnabled );
	}

	return (
			<Table.Row className={ styles.row }>
				<Table.Cell className={ styles.firstColumn }>
					<div
							className={ classNames( { [ styles.hiddenToggle ]: ! props.hasSubscriptions } ) }
							style={ { visibility: props.hasSubscriptions ? "initial" : "hidden" } }
					>
						<Toggle
								onSetEnablement={ debounce( onTogglePress, 300 ) }
								onClickingTheLockedToggle={ props.onToggleDisabled }
								isEnabled={ props.isEnabled }
								locked={ locked }
								toggling={ props.togglingSubscription }
								ariaLabel={ util.format( props.intl.formatMessage( messages.toggleAriaLabel ), props.name ) }
						/>
					</div>
					<img
							className={ classNames( styles.subscriptionLogo, { [ styles.hasSubscriptions ]: props.hasSubscriptions } ) }
							src={ props.icon }
							alt=""
					/>
				</Table.Cell>

				<Table.Cell className={ styles.secondColumn }>
					{ ! props.hasSubscriptions && <Title as="h2" size="3">{ props.name }</Title> }
					<div className={ classNames( styles.subscriptionUsage, { [ styles.hasSubscriptions ]: props.hasSubscriptions } ) }>
						{ props.hasSubscriptions && <Title as="h2" size="3">{ props.name }</Title> }
						<Badge variant="plain">
							<FormattedMessage
									id="subscriptions.remaining" defaultMessage={ "{ howMany } used" }
									values={ { howMany: props.used + "/" + props.limit } }
							/>
						</Badge>
					</div>
					{ props.limit > 0 && anotherLicense }
				</Table.Cell>

				<Table.Cell
						className={ classNames( styles.column, styles.fixed, styles.thirdColumn, { [ styles.isEnabled ]: props.isEnabled } ) }
				>
					<Link href={ "/downloads" }>
						<FormattedMessage id="subscriptions.buttons.download" defaultMessage="Download"/>
					</Link>
				</Table.Cell>
				{ getManageSection( props ) }
			</Table.Row>
	);
}

/**
 * Default activatable product row.
 *
 * @param {object} props The props.
 *
 * @returns {ReactElement} The siteSubscriptionDetail element.
 */
function siteSubscriptionDetailNotChangeable( props ) {
	const rowProps = [];
	if ( props.background ) {
		rowProps.background = props.background;
	}


	return (
			<Table.Row className={ styles.row }>
				<Table.Cell className={ styles.firstColumn }>
					<div
							className={ classNames( { [ styles.hiddenToggle ]: ! props.hasSubscriptions } ) }
							style={ { visibility: props.hasSubscriptions ? "initial" : "hidden" } }
					>
						<Toggle
								isEnabled={ props.isEnabled }
								ariaLabel={ util.format( props.intl.formatMessage( messages.toggleAriaLabelProvisioned ) ) }
								onSetEnablement={ noop }
								onClickingTheLockedToggle={ noop }
								locked={ true }
								toggling={ false }
						/>
					</div>
					<img
							className={ classNames( styles.subscriptionLogo, { [ styles.hasSubscriptions ]: props.hasSubscriptions } ) }
							src={ props.icon }
							alt=""
					/>
				</Table.Cell>

				<Table.Cell className={ styles.secondColumn }>
					<Title as="h2" size="3">{ props.name }</Title>
				</Table.Cell>
				<Table.Cell className={ styles.thirdColumn }>
					<Link href={ "/downloads" }>
						<FormattedMessage id="subscriptions.buttons.download" defaultMessage="Download"/>
					</Link>
				</Table.Cell>
				{ getManageSection( props ) }
			</Table.Row>
	);
}

/**
 * Creates Site Subscriptions component
 *
 * @param {Object} props The props to use
 *
 * @returns {ReactElement} The rendered component.
 */
function SiteSubscriptionDetail( props ) {
	if ( props.accessGivenByProvisionedSubscription && ! props.provisionerAllowsSiteChange ) {
		return siteSubscriptionDetailNotChangeable( props );
	}

	return siteSubscriptionDetailChangeable( props );
}

SiteSubscriptionDetail.propTypes = {
	name: PropTypes.string.isRequired,
	onMoreInfoClick: PropTypes.func.isRequired,
	icon: PropTypes.string.isRequired,
	limit: PropTypes.number.isRequired,
	used: PropTypes.number.isRequired,
	intl: intlShape.isRequired,
	onClose: PropTypes.func.isRequired,
	onToggleSubscription: PropTypes.func.isRequired,
	onToggleDisabled: PropTypes.func.isRequired,
	hasSubscriptions: PropTypes.bool.isRequired,
	subscriptionId: PropTypes.string,
	manageButtonSubscriptionId: PropTypes.string,
	isEnabled: PropTypes.bool,
	accessGivenByProvisionedSubscription: PropTypes.bool,
	togglingSubscription: PropTypes.bool,
	background: PropTypes.string,
	sourceId: PropTypes.number,
};

SiteSubscriptionDetail.defaultProps = {
	isEnabled: false,
	togglingSubscription: false,
	accessGivenByProvisionedSubscription: false,
	subscriptionId: "",
	manageButtonSubscriptionId: "",
	background: "var(--bg-color-white)",
};

export default injectIntl( SiteSubscriptionDetail );
