import PropTypes from "prop-types";
import React from "react";
import ComposerToken from "./ComposerToken";
import styles from "./ComposerTokensStyles.scss";

/**
 * Returns the rendered Orders component.
 *
 * @param {object} props Properties for this element.
 * @returns {ReactElement} PageOrderList element.
 * @constructor
 */
export default function ComposerTokens( props ) {
	const activeComposerTokens = props.composerTokens.filter( ( composerToken ) => {
		return composerToken.enabled === true;
	} );

	if ( activeComposerTokens.length === 0 ) {
		return null;
	}

	return (
		<div className={ styles.composerTokensWrapper }>
			{ activeComposerTokens.map( ( composerToken ) =>
				<ComposerToken
					{ ...composerToken }
					key={ composerToken.id }
					onManageTokenClick={ props.onManageTokenClick }
				/> ) }
		</div>
	);
}

ComposerTokens.propTypes = {
	onManageTokenClick: PropTypes.func.isRequired,
	composerTokens: PropTypes.array,
};

ComposerTokens.defaultProps = {
	composerTokens: [],
};
